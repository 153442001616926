<template>
    <PopupBox title="공장 가입요청" :tabs="popupTabs" showWhiteButton whiteButtonText="반려하기" showBlueButton
            blueButtonText="승인하기" @whiteClick="toggleTextBox" :popNm="'factoryAppr'" @blueClick="getApprConfirm">
        <template v-slot:header>
            <popupHead v-for="(tab, index) in popupTabs" :key="index" @click="activeTab = tab.title"
                       :class="{ active : activeTab === tab.title }">
                {{ tab.title }}
            </popupHead>
        </template>
        <template v-slot:content>
            <popupBody v-if="activeTab === '승인심사'" class="space-y-4">
                <LabelInput type="text" label="가입일시" useTooltip tooltipContent="" :value="info['regDt']" disabled/>
                <LabelInput type="text" label="심사요청 일시" useTooltip tooltipContent="" :value="info['apprDt']" disabled/>
                <LabelInput type="text" label="반려 횟수" useTooltip tooltipContent="" :value="info['rejectCnt']" disabled/>
                <LabelTextarea label="반려 사유" useTooltip tooltipContent="" :value="info['apprMemo']" rows="2" disabled/>
                <ModalTitle title="기본 회원 정보"/>
                <LabelInput type="text" label="플랫폼 구분" useTooltip tooltipContent="" :value="info['platForm']" disabled/>
                <LabelInput type="text" label="가입 채널" useTooltip tooltipContent="" :value="info['channel']" disabled/>
                <LabelInput type="text" label="아이디 (이메일)" useTooltip tooltipContent="" :value="info['id']" disabled/>
                <LabelInput type="text" label="이름" useTooltip tooltipContent="" :value="info['name']" disabled/>
                <LabelInput type="text" label="연락처" useTooltip tooltipContent="" :value="info['tel']" disabled/>
                <LabelImage label="사업자등록증 파일" useTooltip tooltipContent="" id="" name="" />
                <LabelInput type="text" label="이용약관 동의" useTooltip tooltipContent="" :value="info['agree1Dt']" disabled/>
                <LabelInput type="text" label="개인정보취급방침 동의" useTooltip tooltipContent="" :value="info['agree2Dt']" disabled/>
                <ModalTitle title="공장 정보"/>
                <LabelInput type="text" label="공장시설 이름" useTooltip tooltipContent="" :value="info['faNm']" disabled/>
                <LabelInput type="text" label="지역" useTooltip tooltipContent="" :value="info['area']" disabled/>
                <LabelInput type="text" label="사업자등록번호" useTooltip tooltipContent="" :value="info['einNum']" disabled/>
                <LabelInput type="text" label="사업자등록증상 대표자 이름" useTooltip tooltipContent="" :value="info['ceoNm']" disabled/>
                <LabelInput type="text" label="주소" useTooltip tooltipContent="" :value="info['addr']" disabled/>
                <LabelInput type="text" label="상세주소" useTooltip tooltipContent="" :value="info['addrDetail']" disabled/>
                <LabelInput type="text" label="공장 연락처" useTooltip tooltipContent="" :value="info['faTel']" disabled/>
                <ModalTitle title="결제 정보"></ModalTitle>
                <LabelInput type="text" label="대표 결제수단" useTooltip tooltipContent="" :value="''" disabled/>
                <LabelInput type="text" label="결제 시작일" useTooltip tooltipContent="" :value="''" disabled/>
                <LabelInput type="text" label="결제별칭" useTooltip tooltipContent="" :value="''" disabled/>
            </popupBody>
        </template>
    </PopupBox>

    <TextAlert title="반려사유" v-if="showTextBox" @get-result="getRejectConfirm" @close="closeTextBox" whiteText="반려취소" blueText="반려하기">
        <LabelTextarea :value="reJectMemo" placeholder="예: 사업자등록증 파일이 제대로 첨부가 안 되어 있습니다.
        정확하게 첨부하여 재심사 부탁드립니다 :)" rows="6" hiddenLabel @input="getRejectMemo"/>
    </TextAlert>
    <Confirm v-if="confirmData['confirmStat']" :type="confirmData['confirmType']" :title="confirmData['confirmTitle']" :description="confirmData['confirmDescription']" :blueText="'확인'" :whiteText = "'취소'" @getResult="getConfirmResult" @getClose="getConfirmClose"/>
</template>

<script setup>
import LabelTextarea from "@/components/input/LabelTextarea.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import LabelInput from "@/components/input/LabelInput.vue";
import PopupBox from "@/components/modal/PopupBox.vue";
import TextAlert from "@/components/modal/TextAlert.vue";
import Confirm from "@/components/modal/Confirm.vue";

import { ref, reactive, computed } from 'vue';
import { useStore } from 'vuex';
import LabelImage from "@/components/input/LabelImage.vue";

const store = useStore();

const info = computed(() => store.state.factoryAppr.info);

const activeTab = ref('승인심사');
const popupTabs = ref([
    {title: '승인심사'}
]);

const apprGb      = ref('N');
const confirmData = reactive({
    confirmStat         : false,
    confirmType         : '',
    confirmTitle        : '',
    confirmDescription  : ''
});

const reJectMemo    = ref('');
const getRejectMemo = () => {
    reJectMemo.value = event.target.value;
0};

const getConfirmResult = () => {
    confirmData['confirmStat'] = false;

    if(apprGb.value === 'J')
    {
        const res = store.dispatch('factoryAppr/getAppr', { faCd : info.value['faCd'], userId : info.value['id'] });

        if(res)
        {
            store.commit('popup/setStat', { name : 'factoryAppr', stat : false });
            store.dispatch('factoryAppr/getList', { apprGb : 'J' });
        }
    }
    else
    {
        const res = store.dispatch('factoryAppr/getReject', { faCd : info.value['faCd'], userId : info.value['id'], memo : reJectMemo.value });

        if(res)
        {
            store.commit('popup/setStat', { name : 'factoryAppr', stat : false });
            store.dispatch('factoryAppr/getList', { apprGb : 'J' });
            reJectMemo.value  = '';
            showTextBox.value = false;
        }
    }
};

const getConfirmClose = () => {
    confirmData['confirmStat'] = false;
};

const getConfirmSet = (type, title, description) => {
    confirmData['confirmType']        = type;
    confirmData['confirmStat']        = true;
    confirmData['confirmTitle']       = title;
    confirmData['confirmDescription'] = description;
};

const getRejectConfirm = () => {
    apprGb.value = 'A';
    getConfirmSet('confirm', '반려하기', '반려하시겠습니까?');
}

const getApprConfirm = () => {
    apprGb.value = 'J';
    getConfirmSet('confirm', '승인하기', '승인하시겠습니까?');
}

const showTextBox = ref(false);

const toggleTextBox = () => {
    showTextBox.value = true;
};

const closeTextBox = () => {
    showTextBox.value = false;
};
</script>

<style scoped>

</style>