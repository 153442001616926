import { getAxiosData } from '@/assets/js/function';
import router from '@/router'

/**
 * @description 공장 승인 요청 vuex store module
 * @author 김원명, @date 2024-02-23
 */
const factoryApprModule = {
    namespaced : true,
    state: {
        list : [],
        info : getFactoryApprInfo()
    },
    mutations: {
        setList(state, list) {
            state.list = list;
        },
        setInfo(state, info) {
            state.info = info;
        }
    },
    actions: {
        /** 리스트 호출 */
        async getList (context, data)
        {
            try
            {
                const res = await getAxiosData('https://po-data.plansys.kr/fac/facApprList', data);

                console.log(res);

                const list = [];

                res.data.forEach((item) => {
                    list.push({
                        faCd    : item.faCd,
                        id      : item.email,
                        cntry   : '대한민국',
                        area    : item.area,
                        faNm    : item.faNm,
                        ceoNm   : item.ceoNm,
                        tel     : item.tel,
                        einNum  : item.einNum,
                        regDt   : item.regDt,
                        apprDt  : item.apprDt
                    })
                });

                context.commit('setList', list);
            }
            catch (e)
            {
                console.error(e);

                /** 로그인 세션 만료 */
                if(e.response.data.status === 403)
                {
                    alert('세션이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    router.push('/login');
                }
            }
        },
        /** 상세 정보 호출 */
        async getInfo (context, data)
        {
            console.log(data);
            try
            {
                const res = await getAxiosData('https://po-data.plansys.kr/fac/facApprInfo', data);

                console.log(res);

                context.commit('setInfo', {
                    faCd      : data['faCd'],
                    regDt     : res.data['regDt'],
                    apprDt    : res.data['apprDt'],
                    rejectCnt : res.data['rejectCnt'],
                    apprMemo  : res.data['apprMemo'],
                    platForm  : '플랜오더 공장',
                    channel   : res.data['channel'],
                    id        : res.data['id'],
                    name      : res.data['name'],
                    tel       : res.data['tel'],
                    agree1Dt  : res.data['agree1Dt'],
                    agree2Dt  : res.data['agree2Dt'],
                    faNm      : res.data['faNm'],
                    area      : res.data['area'],
                    einNum    : res.data['einNum'],
                    ceoNm     : res.data['ceoNm'],
                    addr      : res.data['addr'],
                    addrDetail: res.data['addrDetail'],
                    faTel     : res.data['faTel']
                });
            }
            catch (e)
            {
                console.error(e);

                /** 로그인 세션 만료 */
                if(e.response.data.status === 403)
                {
                    alert('세션이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    router.push('/login');
                }
            }
        },
        /** 승인처리 */
        async getAppr(context, data)
        {
            try
            {
                const res = await getAxiosData('https://po-data.plansys.kr/fac/facApprAccess', data);

                if(res.status === 200)
                {
                    return true;
                }
            }
            catch(e)
            {
                console.error(e);

                /** 로그인 세션 만료 */
                if(e.response.data.status === 403)
                {
                    alert('세션이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    router.push('/login');
                }

                return false;
            }
        },
        /** 반려처리 */
        async getReject(context, data)
        {
            console.log(data);
            try
            {
                const res = await getAxiosData('https://po-data.plansys.kr/fac/facApprReject', data);

                if(res.status === 200)
                {
                    return true;
                }
            }
            catch(e)
            {
                console.error(e);

                /** 로그인 세션 만료 */
                if(e.response.data.status === 403)
                {
                    alert('세션이 만료되었습니다. 로그인 페이지로 이동합니다.')
                    router.push('/login');
                }

                return false;
            }
        }
    }
};

function getFactoryApprInfo()
{
    return {
        faCd      : '',
        regDt     : '',
        apprDt    : '',
        rejectCnt : 0,
        apprMemo  : '',
        platForm  : '플랜오더 공장',
        channel   : 'E',
        id        : '',
        name      : '',
        tel       : '',
        agree1Dt  : '',
        agree2Dt  : '',
        faNm      : '',
        area      : '',
        einNum    : '',
        ceoNm     : '',
        addr      : '',
        addrDetail: '',
        faTel     : ''
    };
}

export default factoryApprModule;