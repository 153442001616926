<template>
    <ApprHeader />
    <FilterBox />
    <StikyTable :columns="columns" :rows="list" @row-click="getListClick"/>
    <!-- popup은 v-if대신 .active 클래스를 주는 것으로 동작 -->
    <ApprListPopup />
</template>

<script setup>
import StikyTable from "@/components/table/StikyTable.vue";
import ApprHeader from "@/views/common/ApprHeader.vue";
import FilterBox from "@/components/common/FilterBox.vue";
import ApprListPopup from "@/views/factory/approve/ApprListPopup.vue";

import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const columns = [
    { key: 'id',        header: '가입 이메일', class: 'text-brand w17' },
    { key: 'cntry',     header: '국가', class: 'w10' },
    { key: 'area',      header: '지역', class: 'w10' },
    { key: 'faNm',      header: '공장명', class: 'w13' },
    { key: 'ceoNm',     header: '대표자', class: 'w10' },
    { key: 'tel',       header: '휴대폰', class: 'w10' },
    { key: 'einNum',    header: '사업자등록증', class: 'w10' },
    { key: 'regDt',     header: '가입 일시', class: 'w10' },
    { key: 'apprDt',    header: '심사요청 일시', class: 'w10' }
];

const list = computed(() => store.state.factoryAppr.list);

/** 화면 호출 시 */
onMounted(() => {
    store.commit('popup/setStat', { name : 'factoryAppr', stat : false });
    getList();
});

/** 리스트 호출 */
const getList = () => {
    store.dispatch('factoryAppr/getList', { apprGb : 'J' });
}

/** 리스트 클릭 시 */
const getListClick = (data) => {
    store.dispatch('factoryAppr/getInfo', { faCd : data['faCd'], userId: data['id'] });
    store.commit('popup/setStat', { name : 'factoryAppr', stat : true });
}

</script>