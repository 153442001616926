/**
 * @description 로그인 vuex store module
 * @author 김원명, @date 2024-02-28
 */
const loginModule = {
    namespaced : true,
    state: {
        jwt             : null,
        refreshToken    : null
    },
    mutations: {
        setLogin(state, info) {
            state.jwt           = info['jwt'];
            state.refreshToken  = info['refreshToken'];
        }
    },
    actions: {
        login({ commit }, info) 
        {
            commit('setLogin', info);

            localStorage.setItem('info', JSON.stringify(info));
        },
        logout({ commit })
        {
            commit('setLogin', null);
            localStorage.setItem('info', JSON.stringify({ jwt : null}));
        },
        initializeState({ commit }) 
        {
            const info = localStorage.getItem('info');
            
            if (info) 
            {
                const parse = JSON.parse(info);

                commit('setLogin', parse);
            }
        }
    }
};

export default loginModule;